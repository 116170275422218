
export default {
  head() {
    return {
      link: [
        {
          hid: 'canonical',
          rel: 'canonical',
          href: `https://validblocks.com${this.$route.path}`
        }
      ],
      title: 'Valid Blocks',
      description: 'Our blocks are always valid',
      meta: [
        // Site Description
        {
          hid: 'description',
          name: 'description',
          content: 'Our blocks are always valid',
        },
        // Facebook
        {
          hid: 'og:type',
          property: 'og:type',
          content: 'website',
        },
        {
          hid: 'og:url',
          property: 'og:url',
          content: `https://validblocks.com${this.$router.history.current.fullPath}`,
        },
        // Facebook Title
        {
          hid: 'og:title',
          property: 'og:title',
          content: 'Valid Blocks',
        },
        // Facebook Description
        {
          hid: 'og:description',
          property: 'og:description',
          content: 'Our blocks are always valid',
        },
        {
          hid: 'og:image',
          property: 'og:image',
          content: 'https://validblocks.com/social.jpg',
        },
        // Twitter
        {
          hid: 'twitter:site',
          name: 'twitter:site',
          content: '@ValidBlocks'
        },
        {
          hid: 'twitter:creator',
          name: 'twitter:creator',
          content: '@ValidBlocks'
        },
        {
          hid: 'twitter:card',
          name: 'twitter:card',
          content: 'summary_large_image',
        },
        // Twitter Title
        {
          hid: 'twitter:title',
          name: 'twitter:title',
          content: 'Valid Blocks',
        },
        // Twitter Description
        {
          hid: 'twitter:description',
          name: 'twitter:description',
          content: 'Our blocks are always valid',
        },
        {
          hid: 'twitter:image',
          name: 'twitter:image',
          content: 'https://validblocks.com/social.jpg',
        },
      ]
    }
  }
}
