import { render, staticRenderFns } from "./index.vue?vue&type=template&id=6d662b8e&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Header: require('/home/graffino/actions-runner/_work/ValidBlocks/ValidBlocks/components/Header.vue').default,Hero: require('/home/graffino/actions-runner/_work/ValidBlocks/ValidBlocks/components/Hero.vue').default,Network: require('/home/graffino/actions-runner/_work/ValidBlocks/ValidBlocks/components/Network.vue').default,Dashboard: require('/home/graffino/actions-runner/_work/ValidBlocks/ValidBlocks/components/Dashboard.vue').default,WhyUs: require('/home/graffino/actions-runner/_work/ValidBlocks/ValidBlocks/components/WhyUs.vue').default,Contact: require('/home/graffino/actions-runner/_work/ValidBlocks/ValidBlocks/components/Contact.vue').default,Footer: require('/home/graffino/actions-runner/_work/ValidBlocks/ValidBlocks/components/Footer.vue').default})
